<template>
    <div class="work-content">
      <h1 class="page-title">Case Studies</h1>
      <h2 class="page-subtitle">Here are a few high level case studies I've worked on lately.</h2>

      <div class="case-study-grid">
        <div class="case-example">
          <div class="case-img-wrapper">
            <a href="/case-studies/sgf-bot">
              <img class="case-thumbnail" src="@/assets/sgf-bot.png"/>
            </a>
          </div>
          <div class="case-desc">
            <h2><a href="/case-studies/sgf-bot">Springfield Botanical Center Gardens Tour</a></h2>
            <p>
              The Springfield Botanical Center is an internationally accredited garden park and event facility that attracts visitors from around the world.
            </p>
          </div>
        </div>

        <div class="case-example">
          <div class="case-img-wrapper">
            <a href="/case-studies/ritter-springs-webpage">
              <img class="case-thumbnail" src="@/assets/ritter-springs.png"/>
            </a>
          </div>
          <div class="case-desc">
            <h2><a href="/case-studies/ritter-springs-webpage">Ritter Springs Webpage</a></h2>
            <p>
              The Springfield Park Board is the city parks department in Springfield, Missouri. The Park Board manages and cares for over 100 city parks, partners with the public school system to organize before and after school programs for children, manages several community centers, events, and so much more.
            </p>
          </div>
        </div>

        <div class="case-example">
          <div class="case-img-wrapper">
            <a href="/case-studies/sgf-wit-website">
              <img class="case-thumbnail" src="@/assets/sgf-wit-logo.png"/>
            </a>
          </div>
          <div class="case-desc">
            <h2><a href="/case-studies/sgf-wit-website">Springfield Women in Technology Website</a></h2>
            <p>
              SGF Women in Tech is a non-profit group for women and gender minorities to engage, learn, network, and possibly even find work while pursuing or furthering a career in technology.
            </p>
          </div> 
        </div>
      </div>
    </div> 
</template>

<script>

export default {
  name: 'CaseStudies',
  props: {
  }
}
</script>

<style lang="scss">

</style>
