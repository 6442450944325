<template>
    <div class="work-content">
      <h1 class="page-title">Ritter Springs Webpage</h1>

      <div class="case-study-wrapper">
        <img class="case-study-img" src="@/assets/ritter-springs-desktop-mobile-mock.png"/>
        <div>
          <h2>Context</h2>
          <p>
            The Springfield Park Board is the city parks department in Springfield, Missouri. The Park Board manages and cares for over 100 city parks, partners with the public school system to organize before and after school programs for children, manages several community centers, events, and so much more.
          </p>
          <p>
            I joined the team at a time when the Park Board was separating their online presence from the larger City of Springfield website. They launched a dedicated url, put a content management system in place and needed help transitioning the rest. For this particular case study, we’ll look at how and why I created a dedicated webpage for Ritter Springs Park.
          </p>
        </div>
        <div>
          <h2>Problem</h2>
          <div class="img-wrapper">
            <img class="case-study-img keep-small" src="@/assets/ritter-springs-problem.png" />
          </div>
          <p>
            In my role as Web Designer for the Park Board, I implemented and then produced monthly reports on the analytics from Google as well as internal search and interaction analytics of the Park Board website. The great thing about monitoring this data is that it always indicated areas we could improve. I was able to quickly see that Ritter Springs was one of the top parks searched for internally on the site as well as one of the top searches from Google leading to the website. And you know what? We did not have a dedicated webpage for this park. When people landed on the site after searching for this park, they found a parks directory with an icon legend indicating amenities and a phone number for the main parks office. This wasn’t enough for our patrons.
          </p>
        </div>
        <div>
          <h2>Journey</h2>
          <p>
            The main priority for this designated webpage was to showcase the park and highlight the information that our users were looking for. I was able to identify what users were looking for through specific keyword search analytics. The resulting data indicated users wanted to see photos, get information on how to host events or rent the park, what amenities were featured in this park, and detailed map information on how to get there and how to navigate the park upon arrival.
          </p>
          <img class="case-study-img" src="@/assets/ritter-springs-journey.png" />
          <p>
            I then gave the page a place in many prominent areas on the website. The Park is now featured in the first menu navigation dropdown under Special Facilities, it is accessible on the Special Facilities page, and the new page is now linked in the original Parks directory. Finally, I added specific keywords to help users find this page on google and within search on the website itself.
          </p>
          <img class="case-study-img" src="@/assets/ritter-springs-desktop-pages.png" />
          <p>
            The result? In a very brief amount of time we were able to see that when users searched for the park they not only landed on the page I had created but were also spending time there.
          </p>
          <br>
          <br>
        </div>
      </div>
    </div> 
</template>

<script>

export default {
  name: 'RitterSprings',
  props: {
  }
}
</script>

<style lang="scss">

</style>
