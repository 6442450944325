<template>
    <div class="work-content">
      <h1 class="page-title">Springfield Women in Technology Website</h1>

      <div class="case-study-wrapper">
        <img class="case-study-img" src="@/assets/sgf-wit-desktop-mobile-mock.png"/>
        <div>
          <h2>Context</h2>
          <p>
            SGF Women in Tech is a non-profit group for women and gender minorities to engage, learn, network, and possibly even find work while pursuing or furthering a career in technology.
          </p>
          <p>
            As a member of the SGF WIT board and UI/UX Designer and Developer, I quickly volunteered to tackle this project with the team.
          </p>
        </div>
        <div>
          <h2>Problem</h2>
          <div class="img-wrapper">
            <img class="case-study-img keep-small" src="@/assets/sgf-wit-problem.png" />
          </div>
          <p>
            While SGF WIT was actively holding in person events and meeting the overall goal of the group, they lacked an online hub where members could easily find information on how to join, what resources were available to them, and when events were happening.
          </p>
          <p>
            The only branding done for the group was a logo which was created several years beforehand and for which the original file was lost. With this being the case, first things first I focused on branding.
          </p>
          <img class="case-study-img" src="@/assets/sgf-wit-banner.jpg"/>
          <p>
            I modernized the current logo, but more importantly created a file from which we could easily produce what graphic elements we needed.
          </p>
        </div>
        <div>
          <h2>Journey</h2>
          <p>
            Because our top priorities were giving users the ability to connect, that is what we emphasized above the fold. We stated simply who we are and then emphasized how users could join us. Typically this would begin with going to events and meeting other women in tech. Alternatively, the group is very social online so we emphasized that next.
          </p>
          <img class="case-study-img" src="@/assets/sgf-wit-desktop-pages.png"/>
          <p>
            Another important part of the group is providing resources to look for work. Group members regularly submit job listings so we wanted to create a place where women could look for jobs where they may have a contact through the group to help leverage networking.
          </p>
          <p>
            Finally, this non-profit group was not possible without the efforts of sponsors, so we had to include pertinent information toward the bottom of the page.
          </p>
          <img class="case-study-img" src="@/assets/sgf-wit-sponsors.png"/>
          <p>
            This project had simple goals but I found an elegant solution that met the needs of the group.
          </p>
          <p>
            Once the UI/UX Design was complete, I went on to develop the site using Vue.js and the graphics I created using the Adobe Creative Suite. But we can talk about that more on the development side of things.
          </p>
          <br>
          <br>
        </div>
      </div>
    </div> 
</template>

<script>

export default {
  name: 'RitterSprings',
  props: {
  }
}
</script>

<style lang="scss">

</style>
